import { ChoiceGroup, IChoiceGroupOption, Label, MessageBar, MessageBarType } from "@fluentui/react";
import React from "react";

import { BackgroundShade2, SingleInterest, StyledText, WhiteBackground } from "src/components";
// import { MAX_INTERESTS } from "src/constants";
import { useIsMobileView } from "src/hooks";
import { deepCopy } from "src/util";

import { WorkingHours } from "./WorkingHours";

interface IPreferenceListProps {
  interestDictionary: SimpleCompanyInterest[];
  userPreference: WatercoolerUserPreference;
  onModifyPreference: (item: WatercoolerUserPreference) => void;
  isBusy?: boolean;
}

export const PreferenceList = ({
  interestDictionary,
  userPreference,
  onModifyPreference,
  isBusy,
}: IPreferenceListProps) => {
  const onCheckTopic = (checked: boolean, id: string) => {
    const newPreference = deepCopy(userPreference);
    if (checked) {
      newPreference.interests = [...newPreference.interests.filter((item) => item !== id), id];
    } else {
      newPreference.interests = newPreference.interests.filter((item) => item !== id);
    }
    onModifyPreference(newPreference);
  };

  const isMobileView = useIsMobileView();

  const options: IChoiceGroupOption[] = [
    { key: "1", text: "Daily" },
    { key: "2", text: "Alternate days" },
    { key: "5", text: "Weekly" },
    { key: "30", text: "Monthly" },
  ];

  const [meetingFrequency, setMeetingFrequency] = React.useState<string | undefined>(
    userPreference.nextMeetingTimespanInDays.toString()
  );

  function _onChange(ev: any, option?: IChoiceGroupOption): void {
    var meetingFrequency = option?.key ?? "1";
    setMeetingFrequency(meetingFrequency);

    const newPreference = deepCopy(userPreference);
    newPreference.nextMeetingTimespanInDays = Number(meetingFrequency);
    onModifyPreference(newPreference);
  }

  return (
    <BackgroundShade2
      className="md:px-8 md:py-6 md:m-8 gap-8 flex flex-col px-3"
      id="PreferenceList"
      style={{ width: isMobileView ? "100%" : undefined }}
    >
      <StyledText variant="xLarge">Interests</StyledText>
      <div className="mx-0 my-auto grid gap-10 grid-cols-1 md:grid-cols-2 md:gap-1">
        {interestDictionary.map((interest) => {
          return (
            <SingleInterest
              interests={[interest]}
              selectedInterests={userPreference.interests}
              onCheck={onCheckTopic}
              isBusy={isBusy}
            />
          );
        })}
      </div>
      <WorkingHours preference={userPreference} onModifyPreference={onModifyPreference} />

      <StyledText variant="xLarge">Meeting frequency</StyledText>
      <ChoiceGroup selectedKey={meetingFrequency} options={options} onChange={_onChange} />
    </BackgroundShade2>
  );
};
