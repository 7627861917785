import { IconButton } from "@fluentui/react";
import React from "react";

import { StyledText, WhiteBackground } from "src/components";

interface IEventCardProps {
  time: string;
  subHeaderText: string;
  onClickMore?: () => void;
  bottomtext: string;
  children?: React.ReactNode;
}

export const EventCard = ({ bottomtext, time, subHeaderText, children, onClickMore }: IEventCardProps) => {
  return (
    <WhiteBackground
      className="h-[108px]  p-4 rounded-xl flex flex-col justify-between"
      style={{
        boxShadow: "0px 0.15px 0.45px rgba(0, 0, 0, 0.11), 0px 0.8px 1.8px rgba(0, 0, 0, 0.13)",
      }}
    >
      <div className="flex justify-between">
        <div className="flex flex-col">
          <StyledText variant="large" className="font-bold">
            {time}
          </StyledText>
          <StyledText>{subHeaderText}</StyledText>
        </div>
        <div>{onClickMore && <IconButton iconProps={{ iconName: "More" }} onClick={onClickMore} />}</div>
      </div>
      <div className="flex  justify-between">
        <div>
          <StyledText variant="medium">{time}</StyledText>
        </div>
        <div>{children}</div>
      </div>
    </WhiteBackground>
  );
};
